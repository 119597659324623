.App {
  text-align: center;
}

li {
  margin-left: 20px;
  list-style-type: disc;
}

@import url('https://fonts.googleapis.com/css?family=Lexend:100,200,300,400,500,600,700');
