html {
  height: 100%
}

body {
  height: 100%
}

#root {
  height: 100%
}

@import url('https://fonts.googleapis.com/css?family=Lexend:100,200,300,400,500,600,700,800');
